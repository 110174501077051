/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import SIRIUSInstance from "../../services/sirius";

export default {
  SET_BEARER(state, accessToken) {
    SIRIUSInstance.defaults.headers.common["x-access-token"] = accessToken;
  },
};
