const STORAGE_KEY = "sirius-key";

export const saveDataInStorage = (data) => {
  try {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const getDataInStorage = () => {
  try {
    const data = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    return data || {};
  } catch {
    return {
      error: true,
    };
  }
};

export const updateDataInStorage = (newData) => {
  try {
    const oldData = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    sessionStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        ...oldData,
        ...newData,
      })
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const clearStorage = () => {
  try {
    sessionStorage.removeItem(STORAGE_KEY);
    return true;
  } catch {
    return false;
  }
};
