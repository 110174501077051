/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import vueKanban from "vue-kanban";
import money from "v-money";
Vue.use(vueKanban);
Vue.use(Vuesax);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false,
});

import acl from "./acl/acl";

// axios

import axios from "./services/sirius";
Vue.prototype.$http = axios;

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

// VeeValidate
import VeeValidate, { Validator } from "vee-validate";
import attributesPTBR from "vee-validate/dist/locale/pt_BR";

const config = {
  events: "input|blur",
};

Vue.use(VeeValidate, config);

//Vue.use(VeeValidate);

const dict = {
  custom: {
    password_repeat: {
      is: "Senhas não conferem",
    },
  },
};

Validator.localize("pt_BR", attributesPTBR);

Validator.localize("pt_BR", dict);

// V-Mask
import VueMask from "v-mask";
Vue.use(VueMask);

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

new Vue({
  router,
  acl,
  store,
  render: (h) => h(App),
}).$mount("#app");
